import { Share } from './../../models/share';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './../../services/auth/auth.service';
import { CreatorPage } from './../../models/creator-page.interface';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SafeStyle, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { User } from '../../models';
import { take, mergeMap, switchMap, map, filter, tap } from 'rxjs/operators';
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs';

enum Tab {
  PRESENTATION,
  SHARES,
}

@Component({
  selector: 'sikty-creator-page',
  templateUrl: './creator-page.component.html',
  styleUrls: ['./creator-page.component.scss']
})
export class CreatorPageComponent implements OnInit {
  public Tab = Tab;
  public tab: Tab = Tab.PRESENTATION;

  public keys = Object.keys;
  public values = Object.values;

  public pageData: CreatorPage;
  public pageDataObs: BehaviorSubject<CreatorPage>;
  public banner: SafeStyle;

  public channelsForm: FormGroup;

  public get supportsCount(): number {
    return Object.values(this.pageData.channels).reduce((count, channel) => {
      return count + channel.supports;
    }, 0);
  }

  public shares$: Observable<Share[]>;

  constructor(
    private route: ActivatedRoute,
    private afStore: AngularFirestore,
    private modalService: NgbModal,
    private fb: FormBuilder,
    public auth: AuthService,
  ) {
    this.pageData = this.route.snapshot.data.pageData;
    this.pageDataObs = new BehaviorSubject(this.pageData);
    this.afStore.collection('pages').doc(this.pageData.id)
      .valueChanges().subscribe((cp: CreatorPage) => this.pageDataObs.next(cp));

    this.channelsForm = this.fb.group(Object.keys(this.pageData.channels).reduce((acc, cid) => {
      acc[cid] = this.fb.control(true);
      return acc;
    }, {}));

    this.auth.user$
      .pipe(
        tap(u => this.channelsForm[u ? 'enable' : 'disable']()),
        filter(Boolean),
      )
      .subscribe(async user => {
        const supports = (await this.supportsRef(this.auth.user.id).get().toPromise()).data();
        if (!supports) return;

        Object.entries(supports).forEach(([cid, value]) => {
          this.channelsForm.get(cid).setValue(value);
        });
      });
  }

  ngOnInit() {
    this.shares$ = this.afStore.collection('shares', (ref =>
      ref.where('page', '==', this.pageData.id).orderBy('createdAt', 'desc')
    )).valueChanges();
  }

  private supportsRef(uid) {
    return this.afStore
      .collection('users')
      .doc(uid)
      .collection('supports')
      .doc(this.pageData.id);
  }

  public open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-support-title', centered: true }).result
      .then((result) => {
        // console.log({ result });
      }, (reason) => {
        // console.log({ reason });
      });
  }

  public async validateSupport() {
    let user = this.auth.user;
    if (!user) {
      try {
        user = await this.auth.login();
      } catch (error) {
        return console.error('Can\'t support if user is not logged in');
      }
    }

    return this.supportsRef(this.auth.user.id).set(this.channelsForm.value);
  }
}
