import { of, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';

import { mergeMap, switchMap, take, tap, map, filter } from 'rxjs/operators';
import { User } from '../../models';

const USER_KEY = 'sikty_user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedIn$ = new BehaviorSubject(!!this.afAuth.auth.currentUser);
  public user$ = new BehaviorSubject(this.user);
  public get user(): User {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }
  public set user(u: User) {
    if (u) localStorage.setItem(USER_KEY, JSON.stringify(u));
    else localStorage.removeItem(USER_KEY);

    this.user$.next(this.user);
  }

  constructor(
    public afAuth: AngularFireAuth,
    private afStore: AngularFirestore
  ) {
    this.afAuth.auth.setPersistence(auth.Auth.Persistence.LOCAL);

    this.afAuth.authState.pipe(
      tap(u => this.loggedIn$.next(!!u)),
      mergeMap(user =>
        user ?
          this.afStore.collection('users').doc(user.uid)
            .snapshotChanges()
            .pipe(
              map(p => p.payload),
              filter(d => d.exists),
              map(d => ({ id: d.id, ...d.data() }))
            )
          : of(null)
      ),
    ).subscribe(u => this.user = u);
  }

  public login(): Promise<User> {
    return this.afAuth.auth.signInWithPopup(new auth.TwitterAuthProvider())
      .then(async ({ user, credential, additionalUserInfo }) => {
        const { displayName, email, photoURL } = user;
        const { accessToken, providerId, secret } = credential as any;
        const { username, profile } = additionalUserInfo;

        const tmp = {
          displayName,
          email,
          photoURL,
          username,
          providers: {}
        };

        delete (profile as any).status;
        tmp.providers[providerId.replace('.', '_')] = {
          username,
          profile,
          credential: { accessToken, secret }
        };

        const userDb = this.afStore.collection('users').doc(user.uid);

        const { exists } = await userDb.ref.get();
        console.log(this.afAuth.auth.currentUser);
        console.log({ exists }, tmp);
        if (exists) await userDb.update(tmp);
        else await userDb.set(tmp);

        return userDb.snapshotChanges().pipe(
          take(1),
          map(p => p.payload),
          map(d => ({ id: d.id, ...d.data() }))
        ).toPromise();
      });
  }

  public hasRole(role): boolean {
    if (!this.user || !this.user.roles) return false;
    return this.user.roles.includes(role);
  }

  public logout() {
    this.afAuth.auth.signOut();
  }

  public getIdToken() {
    return this.afAuth.auth.currentUser.getIdToken();
  }
}
