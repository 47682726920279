import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component } from '@angular/core';

@Component({
  selector: 'sikty-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public pages: Observable<{}[]>;

  constructor(
    private afStore: AngularFirestore
  ) {
    this.pages = this.afStore.collection('pages',
      ref => ref
        .where('visible', '==', true)
        .orderBy('supports', 'desc')
        .limit(5)
    ).valueChanges();
  }
}
