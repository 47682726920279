import { AuthService } from './../services/auth/auth.service';
import { CreatorPage } from './../models/creator-page.interface';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map, tap, filter } from 'rxjs/operators';

@Injectable()
export class CreatorPageResolver implements Resolve<{}> {
  constructor(
    private afStore: AngularFirestore,
    private auth: AuthService,
    private router: Router
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const doc = await this.getPageDoc(route.params.page);
      if (!doc) throw Error();

      const page: CreatorPage = doc.data() as CreatorPage;

      // retrieve owners
      page.owners = await Promise.all(page.owners.map(uid =>
        this.afStore.collection('users').doc(uid as string).valueChanges().pipe(take(1)).toPromise()
      ));

      return { id: doc.id, ...page };
    } catch (error) {
      this.router.navigateByUrl('/');
    }
  }

  private async getPageDoc(pid: string): Promise<any> {
    let doc;
    try {
      doc = await this.afStore
        .collection('pages', ref => ref
          .where('slug', '==', pid)
          .where('visible', '==', true)
        )
        .snapshotChanges()
        .pipe(
          take(1),
          map(([p]) => p),
          map(p => p.payload.doc),
        )
        .toPromise();
    } catch {
      const connected = await this.auth.loggedIn$.pipe(take(1)).toPromise();
      if (!connected) throw Error('Not connected');

      try {
        doc = await this.afStore
          .collection('pages', ref => ref
            .where('slug', '==', pid)
            .where('visible', '==', false)
            .where('owners', 'array-contains', this.auth.afAuth.auth.currentUser.uid)
          )
          .snapshotChanges()
          .pipe(
            take(1),
            map(([p]) => p),
            map(p => p.payload.doc),
          )
          .toPromise();
        console.log('Found private page');
      } catch (error) {

        console.log('No private page found or not authorized');
        doc = null;
      }
    }

    return doc;
  }
}
