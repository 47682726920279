import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { NgxTweetService } from 'src/app/services/twitter/ngx-tweet.service';

@Component({
  selector: 'sikty-tweet',
  templateUrl: './tweet.component.html',
  styleUrls: ['./tweet.component.scss']
})
export class TweetComponent {
  @ViewChild('tweetDisplay') tweet: ElementRef;
  private twitterData: any;

  @Input() private options: any = {};
  @Input() set tweetId(id: string) {
    console.log(this.options);
    if (id) this.twitterData.widgets.createTweet(id, this.tweet.nativeElement, this.options)
    else this.tweet.nativeElement.innerHtml = '';
  }

  constructor(
    private tweetService: NgxTweetService
  ) {
    this.loadScript();
  }

  private async loadScript() {
    this.twitterData = await this.tweetService.loadScript().toPromise();
  }
}
