import { NgModule } from '@angular/core';

import { BgImagePipe } from './pipes/bg-image.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { TweetComponent } from './components/tweet/tweet.component';

@NgModule({
  declarations: [
    BgImagePipe,
    SanitizeHtmlPipe,
    TweetComponent,
  ],
  exports: [
    BgImagePipe,
    SanitizeHtmlPipe,
    TweetComponent,
  ],
})
export class SharedModule { }
