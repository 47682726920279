import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'bgImage'
})
export class BgImagePipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(value: any): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${value})`);
  }
}
